import React from 'react';
import PropTypes from 'prop-types';
import { PageContainer, Snackbar } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import Header from '../components/Header';
import { BannerContext } from '../../context/BannerContext';
import ProductBanner from '../components/ProductBanner';

import styles from './AdminLayout.module.css';

/**
 *
 * @param root0
 * @param root0.children
 */
const AdminLayout = ({ children }) => {
  const { user, loggedInUser } = useAuth();
  if (!user) {
    return null;
  }
  const isViewingAs = loggedInUser?.userId !== user.userId;

  return (
    <PageContainer viewingAsOther={isViewingAs}>
      <Header />
      <div className={classnames('d-flex', styles.pageBody)}>
        <aside id="side-nav-container" className={styles.sideNav}></aside>
        <BannerContext.Provider>
          <section id="content-area" className={styles.contentWrapper}>
            <ProductBanner />
            {children}
          </section>
        </BannerContext.Provider>
      </div>
      <Snackbar autoHideDuration={5000} />
    </PageContainer>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminLayout;
