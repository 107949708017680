// import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import HelpCenterWidget from './ui/components/HelpCenterWidget';

import MyInfo from './ui/pages/MyInfo';
import HelpCenter from './ui/pages/HelpCenter';
import HelpCenterV1 from './ui/pages/HelpCenterV1';
import NotFoundPage from './ui/pages/NotFoundPage';
import NotSupportedBrowser from './ui/pages/NotSupportedBrowser';
import AdminLayout from './ui/layouts/AdminLayout';
import FullPageLayout from './ui/layouts/FullPageLayout';
import { BannerContext } from './context/BannerContext';

export {
  HelpCenterWidget,
  MyInfo,
  HelpCenter,
  HelpCenterV1,
  NotFoundPage,
  NotSupportedBrowser,
  AdminLayout,
  FullPageLayout,
  BannerContext,
};
